var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        justify: "center",
        value: _vm.value,
        persistent: "",
        "max-width": "400",
      },
    },
    [
      _c("v-card", { attrs: { color: "#fafafa" } }, [
        _c(
          "section",
          { staticClass: "ma-0 pa-6" },
          [
            _c(
              "v-row",
              { attrs: { justify: "center" } },
              [
                _c("v-icon", { attrs: { "x-large": "", color: "blue" } }, [
                  _vm._v("mdi-arrow-up-bold-box"),
                ]),
              ],
              1
            ),
            _c("v-row", { attrs: { justify: "center" } }, [
              _c("span", { staticClass: "pa-4" }, [
                _vm._v(' Rerun "' + _vm._s(_vm.item.name) + '"? '),
              ]),
              _c("span", { staticClass: "pa-4" }, [
                _vm._v(" Warning: This will overwrite all relevant data. "),
              ]),
            ]),
            _c(
              "v-row",
              { staticClass: "pa-4", attrs: { justify: "center" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "elevation-1",
                    attrs: { height: "40", outlined: "" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("update:value", false)
                      },
                    },
                  },
                  [_vm._v(" No ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "white--text ml-2",
                    attrs: { color: "blue", height: "40" },
                    on: {
                      click: function ($event) {
                        return _vm.resubmitItem(_vm.item.id)
                      },
                    },
                  },
                  [_vm._v("Yes ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }